
// Write your custom style. Feel free to split your code to several files
.images-border-radius > div {
    width: 300px;
    height: 300px;
    border-radius: 150px;
    background-position: center center;
    background-size: 45%;
    background-repeat: no-repeat
}

.backgroundColorGreen {
    background-color: #9bbe86;
}
.colorGreen {
    color: #9bbe86;
}
.backgroundColorPurple {
    background-color: #9c4c6a;
}
.backgroundColorOrange {
    background-color: #d1ba6c;
}
.backgroundColorBlue {
    background-color: #6eabb0;
}
.colorBlue {
    color: #6eabb0;
}
.backgroundColorGrey {
    background-color: #a9a9a9;
}
.colorLightGra, select option:first-child{
    color:#c9ccdb !important;
}
.navbar-brand img, .logotype img{
    max-height: 40px;
}
.large-numbers{
    font-family: Cairo;
}
.form-control{
    border: 1px solid #9f9f9f;
    color:#494949;
}
.form-control:focus{
    border: 1px solid #747474;
}
.py-6 > div {
  font-size: .875em !important; 
}
@media all and (max-width: 768px) {
    .mr-5 {
        width: 40px;
    }
    .images-border-radius {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .images-border-radius > div {
        width: 200px;
        max-width: 200px;
        height: 200px;  
    }
        .display-4{
            font-size:2rem !important;
        }
        
    }

